import * as React from 'react';
import ButtonAsNativeLink from 'styleguide/components/Button/ButtonAsNativeLink';
import Span from 'styleguide/components/Span/Span';

const Block = ({ title, description, ...props }) => (
  <div className="flex items-start text-left -md:mt-14 -md:items-center -md:first:mt-0">
    <Span className="font-hvBold text-6xl text-shades-0 -xs:text-5xl -xs:leading-[58px]">{props.number}</Span>
    <div className="ml-4 max-w-[280px] text-shades-0 -md:max-w-full">
      <Span className="sub-heading-bold mb-4 block tracking-normal">{title}</Span>
      <Span className="paragraph-medium-desktop">{description}</Span>
    </div>
  </div>
);

const StartSteps = () => (
  <div className="relative mx-auto mt-24 flex justify-center bg-blue bg-contain bg-center bg-no-repeat py-24 md:px-4 md:pb-20">
    <div className="relative z-10 max-w-base">
      <div className="flex w-full justify-center">
        <div className="mx-0 mt-0 max-w-xl text-center font-hvBold text-4xl text-shades-0 md:py-0 md:text-5xl lg:text-6xl -md:max-w-md">
          Let&apos;s get down to business.
        </div>
      </div>
      <div
        className="mx-auto mt-16 grid w-full justify-items-center sm:grid-cols-1 md:grid-cols-3 md:gap-x-10
      xl:gap-x-16 -md:flex -md:flex-col -md:items-center -md:px-28 -sm:px-5 -xs:mt-10"
      >
        <Block
          number="1"
          title="Start a project"
          description="Select a product to learn about features and get a quote for your project deadline."
        />
        <Block
          number="2"
          title="Upload your designs"
          description="Send us your files via our uploader. After checkout, we'll review your designs to make sure things are right."
        />
        <Block
          number="3"
          title="Approve proof"
          description="Check your email to approve your digital proof and confirm order details. Project complete!"
        />
      </div>
      <div className="mt-16 w-full text-center -sm:px-5 -xs:mt-10">
        <ButtonAsNativeLink
          buttonType="link"
          className="-sm:w-full"
          color="orange"
          textStyle="medium"
          target="/products"
          size="sm"
        >
          Start Now
        </ButtonAsNativeLink>
      </div>
    </div>
  </div>
);

export default StartSteps;
